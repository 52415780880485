/* Services.css */

.services-section {
    background-color: #1c1c1c;
    color: #fff;
}

.title-section .line {
    width: 65px;
    height: 2px;
    background-color: #2f2f2f;
    margin: 0 auto;
    /* animation: changeColor 3s infinite; */
}

@keyframes changeColor {
    0% {
        background-color: #2f2f2f; /* Initial color */
    }
    50% {
        background-color: #e5493a; /* Color at the middle of the animation */
    }
    100% {
        background-color: #2f2f2f; /* Final color */
    }
}

.services-section-description {
    max-width: 60%;
    margin-bottom: 2rem;
}

.service-col {
    text-align: center; /* left */
    cursor: pointer;
}

.service-icon img {
    width: 94px;
    height: auto;
}

.service-title {
    color: #a1a1a1;
    font-family: Segeo-UI-Regular;
    font-size: 1.3em;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.service-title-ar {
    color: #a1a1a1;
    font-family: Hacen-Regular;
    font-size: 1.3em;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.service-description {
    font-size: 1em;
    color: #aaa;
}


.service-description-p {
    font-family: Segeo-UI-Regular;
    font-size: 1.1em;
    color: #464646;
    max-width: 85%;
}

.service-description-p-ar {
    font-family: Hacen-Regular;
    font-size: 1.1em;
    color: #464646;
    max-width: 85%;
}


.service-line {
    width: 65px;
    height: 2px;
    background-color: #393939;
    position: relative;
    bottom: 0;
    transition: background-color 0.3s;
}

.services-svg-icon {
    width: 94px;
    height: auto;
}

.services-svg-icon path {
    fill: #464646;
    transition: all 0.5s;
}

.service-selected-active path {
    fill: #e5493a;
}



/* .service-col:hover .service-title {
    color: #a1a1a1;
}

.service-title {
    color: #464646;
} */


@media (min-width: 1100px) {
    .services-svg-icon:hover path {
        fill: #a1a1a1;
    }

    .service-col:hover .service-icon .services-svg-icon path {
        fill: #a1a1a1;
    }
    .service-col:hover .service-line {
        background-color: #a1a1a1;
    }
}

.service-line-active {
    background-color: #e5493a;
}

@media (max-width: 786px) {
    .services-section-description {
        font-size: 1em;
        text-align: justify !important;
        max-width: 85%;
    }

    .service-title {
        font-size: 1em !important;
    }

    .service-title-ar {
        font-size: 1em !important;
    }

    .service-description-p {
        font-size: 0.9em;
        text-align: center;
    }

    .service-description-p-ar {
        font-size: 0.9em;
        text-align: center;
    }

    .services-svg-icon {
        width: 80px;
        height: auto;
    }

    .service-row {
        margin: 0 !important;
    }
}



    .spot-light {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background: radial-gradient(circle at var(--x) var(--y), transparent 50%, #a1a1a1 20%);
        /* mix-blend-mode: overlay;  */
        mix-blend-mode: color;
        /* to make it white */
        /* mix-blend-mode: multiply; */
    }


    .spot-white-light {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background: radial-gradient(circle at var(--x, 50%) var(--y, 50%), transparent 60%, #1c1c1c 50%);
        mix-blend-mode: color;
        z-index: 1;
    }
    
    /* .services-section-description {
        color: red;
    } */


    /* .services-section-description {
        transition: color 0.5s ease-in;
    } */

    /* .services-section-description:hover {
        color: #a1a1a1;
    } */


    