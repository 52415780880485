@font-face {
    font-family: 'Barlow-Regular';
    src: url('../../BarlowCondensed-Regular.ttf');
  }
  
  body, html {
    height: 100%;
    margin: 0;
    /* overflow: hidden; */
    font-family: Barlow-Regular;
  }
  
  .bgvideo {
    background-color: black;
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -444;
  }
  
  .bgimg {
    position: fixed;
    width: 100%;
    /* height: calc(var(--vh, 1vh) * 100); */
    height: 100%;
    color: white;
    font-family: "Courier New", Courier, monospace;
    font-size: 25px;
  }
  
  .topleft {
    background-image: './logo.svg';
    position: absolute;
    top: 0;
    left: 25px;
  }
  
  .bottomleft {
    position: absolute;
    bottom: 0;
    left: 25px;
    font-family: Barlow-Regular;
  }
  
  @media (max-width: 768px) {
    .bottomleft {
      bottom: 1%;
      z-index: 999;
      /* top: 90vh; */
    }
  }
    