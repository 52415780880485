/* ContactForm.css */

.contact-form-container {
    height: 100vh;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.contact-form-content {
    /* background-color: rgba(0, 0, 0, 0.5); Add transparency */
    padding: 2rem;
    border-radius: 8px;
}

/* .line {
    width: 50px;
    height: 3px;
    background-color: #e5493a;
    margin: 0 auto;
} */

.contact-title {
    color: white;
    font-size: 2rem;
    margin-top: 10px;
    margin-bottom: 30px;
}

.form-label {
    color: rgba(255, 255, 255, 0.9);
    margin: 0;
    font-family: Segeo-UI-Bold;
    font-size: 1.2em;
}

.form-label-ar {
    color: rgba(255, 255, 255, 0.9);
    margin: 0;
    font-family: Hacen-Bold;
    font-size: 1.2em;
}

.message-form-input {
    min-height: 100px !important;
}

.form-input {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 12px;
    height: 45px;
    color: white;
    transition: background-color 0.5s ease;
}

.form-input::placeholder {
    font-family: Segeo-UI-Light;
}

.form-input-ar::placeholder {
    font-family: Hacen-Light;
}

.send-button {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 14px;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    min-width: 310px;
    font-family: Segeo-UI-Bold;
    font-size: 1.2em;
}

.send-button-ar {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 14px;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    min-width: 310px;
    font-family: Hacen-Bold;
    font-size: 1.2em;
}

.send-button:hover {
    background-color: rgba(131, 131, 131, 0.8);
    color: white;
}

.send-button-ar:hover {
    background-color: rgba(131, 131, 131, 0.8);
    color: white;
}

.form-description {
    color: white;
    font-size: 1.2em;
    font-family: Segeo-UI-Bold;
    text-align: justify;
}

.form-description-ar {
    color: white;
    font-size: 1.2em;
    font-family: Hacen-Bold;
    text-align: justify;
}

.form-input::placeholder {
    color: rgba(255, 255, 255, 0.8); /* Change this to your desired placeholder color */
    font-size: 1em;
}

.form-input:focus {
    background-color: rgba(131, 131, 131, 0.8);
    outline: none;
    box-shadow: none;
    border: 1px solid #ccc; /* Optional: Adjust the border color if needed */
    color: white;
}

.form-input:hover {
    background-color: rgba(131, 131, 131, 0.8);
}


@media (max-width: 768px) {
    .contact-form-container {
        min-height: 105vh;
    }

    .form-label {
        font-size: 1em !important;
    }
    /* .contact-form-content {
        margin-top: 150px !important;
    } */

    .form-description {
        font-size: 0.9em;
    }

    .form-description-ar {
        font-size: 0.9em;
    }

    .send-button {
        font-size: 1em !important;
        min-width: 275px;
    }

    .send-button-ar {
        font-size: 1em !important;
        min-width: 275px;
    }
}


.success-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .success-icon svg {
    color: #28a745; /* Success color */
    width: 30px;
    height: 30px;
  }