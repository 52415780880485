
.follow-us {
    background-size: cover;
    padding: 0;
    margin: 0;
    background-repeat: no-repeat;
    background-position: center;
    height: 74vh;
    position: relative;
}

.follow-us-description {
    font-family: BarlowCondensedMeduim;
    font-size: 22.4px;
    letter-spacing: 1.4px;
}


.contact-info-icons:hover {
    background-color: rgb(180, 169, 130);
    transition: 0.3s all;
}



.privacy-policy {
    max-width: 75%;
    position: absolute;
    left: 50px;
    bottom: 50px;
}

.privacy-policy p {
    font-family: Barlow-tiktag-regular;
    letter-spacing: 0px;
    line-height: 10px;
    color: rgba(255, 255, 255, 0.9);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}


.contact-info-row {
    border: 1px solid white;
    border-radius: 25px;
    width: 80%;
}

.contact-info-icon {
    width: 42px;
    height: 42px;
    align-items: center;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 50%;
    transition: background-color 0.5s ease, margin-right 0.5s ease-in-out, margin-left 0.5s ease-in-out;
    margin-right: 3px;
    margin-left: 3px;
}


.contact-info-icon:hover {
    background-color: #787878;
    margin-right: 4px;
    margin-left: 4px;
}

.whatsapp-button {
    border: 1px solid white;
    text-decoration: none;
    border-radius: 45px;
    width: 150px; /* Adjust the width as needed */
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: transparent; /* Optional: make the background transparent */
    margin-right: 5px; /* Adjust the spacing between the icon and text */
    transition: background-color 0.5s ease;
}

.whatsapp-button:hover, .whatsapp-button:focus {
    border: 1px solid white;
    background-color: #787878;
}


.get-in-touch-title {
    color: rgba(255, 255, 255, 0.9);
    font-family: Segeo-UI-Bold;
    font-size: 1.2em;
}

.get-in-touch-title-ar {
    color: rgba(255, 255, 255, 0.9);
    font-family: Hacen-Bold;
    font-size: 1.2em;
}

.get-in-touch-description {
    font-family: Segeo-UI-Regular;
}

.get-in-touch-description-ar {
    font-family: Hacen-Regular;
}

.bootstrap-icon {
    color: white;
    font-size: 22px;
}


.first-contact-info-col {
    min-width: 200px;
}

@media (max-width: 768px) {

    /* @media (min-height: 740px) {
        .follow-us { 
            min-height: 100vh !important;
        }
    }

    @media (max-height: 739px) {
        .follow-us {
            min-height: 740px !important;
        }
    } */

    .follow-us {
        min-height: 740px !important;
    }


    .get-in-touch-description {
        font-size: 0.8em !important;
    }

    .get-in-touch-description-ar {
        font-size: 0.8em !important;
    }

    .get-in-touch-title {
        font-size: 1.1em;
        margin-right: 10px;
        margin-left: 10px;
    }

    .get-in-touch-title-ar {
        font-size: 1.1em;
        margin-right: 10px;
        margin-left: 10px;
    }

    .contact-info-row {
        width: 85%;
    }

    .contact-info-icon {
        margin-right: 3px;
        margin-left: 3px;
    }

    .first-contact-info-col {
        min-width: 10px;
    }
}


/* gallery follow us */

.btn-flip {
    opacity: 1;
    outline: 0;
    color: #fff;
    line-height: 40px;
    position: relative;
    text-align: center;
    letter-spacing: 1px;
    display: inline-block;
    text-decoration: none;
    font-family: 'Open Sans';
    text-transform: uppercase;
    width: 50%;
  }
  
  .btn-flip:hover:after {
    opacity: 1;
    transform: translateY(0) rotateX(0);
  }
  
  .btn-flip:hover:before {
    opacity: 0;
    transform: translateY(50%) rotateX(90deg);
  }
  
  .btn-flip:after {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    color: #323237;
    display: block;
    transition: 0.5s;
    position: absolute;
    background-color: rgba(131, 131, 131, 0.8);
    color: white;
    content: attr(data-back);
    transform: translateY(-50%) rotateX(90deg);
  }
  
  .btn-flip:before {
    top: 0;
    left: 0;
    opacity: 1;
    color: #adadaf;
    display: block;
    padding: 0 30px;
    line-height: 40px;
    transition: 0.5s;
    position: relative;
    background: transparent;
    content: attr(data-front);
    transform: translateY(0) rotateX(0);
  }


  /* latest */

.btn-flip {
    opacity: 1;
    outline: 0;
    color: #fff;
    line-height: 40px;
    position: relative;
    text-align: center;
    letter-spacing: 1px;
    display: inline-block;
    text-decoration: none;
    font-family: 'Open Sans';
    text-transform: uppercase;
}

.btn-flip .front-text,
.btn-flip .back-icons {
    display: block;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.5s;
    padding: 0 30px;
    line-height: 40px;
}

.btn-flip .front-text {
    color: #adadaf;
    background: transparent;
    transform: translateY(0) rotateX(0);
    opacity: 1;
    border: 1px solid white;
    border-radius: 14px;
}

.btn-flip .back-icons {
    color: #323237;
    background: rgba(131, 131, 131, 0.8);
    transform: translateY(-50%) rotateX(90deg);
    opacity: 0;
    border-radius: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.btn-flip:hover .front-text {
    transform: translateY(50%) rotateX(90deg);
    opacity: 0;
}

.btn-flip:hover .back-icons {
    transform: translateY(0) rotateX(0);
    opacity: 1;
}

.icon {
    display: inline-block;
    margin: 0 5px;
    /* Style your icons here */
}

.icon1::before {
    content: '\f09a'; /* Font Awesome Facebook Icon */
}

.icon2::before {
    content: '\f099'; /* Font Awesome Twitter Icon */
}

.icon3::before {
    content: '\f16d'; /* Font Awesome Instagram Icon */
}


@media (max-width: 786px) {
    .btn-flip {
        width: 80%;
    }

    .work-page-service-title {
        font-size: 0.75em !important;
    }
}