.footer-links {
    color: #555555;
    font-family: Segeo-UI-Light;
    letter-spacing: 2px;
    font-size: 1.2em;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.30);
}

.footer-links-ar {
    color: #555555;
    font-family: Hacen-Light;
    font-size: 1.2em;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.30);
}

.footer-links:hover {
    color: #e5493a;
}

.footer-links-ar:hover {
    color: #e5493a;
}

#footerLogo {
    width: 140px;
}

@media (max-width: 786px) {
    .not-on-mobile-footer-links {
        display: none;
    }
    .logo-footer {
        position: relative;
        left: 25%;
    }
    #footerLogo {
        width: 110px;
    }
}