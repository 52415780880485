/* .recent-work-section {
    overflow: hidden;
    background-color: #202020;
    color: white;
}

.title-section .line {
    width: 50px;
    height: 3px;
    background-color: red;
    margin: 0 auto;
}

.section-title {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.section-description {
    font-size: 1rem;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 2rem;
}

.recent-work-slider {
    padding: 2rem 0;
    overflow: visible; 
}

.recent-work-slide {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    transition: transform 0.3s, height 0.3s, border 0.3s, z-index 0.3s;
    margin: 0 !important; 
}

.video-thumbnail {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    transition: transform 0.3s, height 0.3s, border 0.3s;
}

.recent-work-slide.selected .video-thumbnail {
    border: 5px solid red;
    height: 400px;
    transform: translateY(-100px);
    z-index: 10; 
}

.video-overlay {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.video-overlay h4 {
    margin: 0;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    width: calc(100% / 3); 
}

.video-thumbnail video {
    width: 100%;
    height: 100%;
    object-fit: cover;
} */


.recent-work-section {
    overflow: hidden;
    background-color: #202020;
}

.recent-work-slider {
    height: 450px !important;
    padding: 2rem 0;
    /* overflow: visible; */
}

.recent-work-slide {
    /* display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative; */
    transition: transform 0.3s, width 0.3s, height 0.3s, border 0.3s, z-index 0.3s;
    margin: 5px !important;
    background-color: #202020 !important;
}

.video-thumbnail {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    transition: transform 0.3s, width 0.3s, height 0.3s, border 0.3s;
}

.recent-work-slide {
    z-index: -999;
}

.recent-work-slide.selected .video-thumbnail {
    /* border: 3px solid #e5493a; */
    transform: scale(1.1);
    z-index: 999; 
    margin-top: 5px;
}

@property --angle {
  syntax: '<angle>';
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: '<percentage>';
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: true;
}

:root {
	--d: 2500ms;
	--angle: 90deg;
	--gradX: 100%;
	--gradY: 50%;
	/* --c1: rgba(168, 239, 255, 1);
	--c2: rgba(168, 239, 255, 0.1); */
    --c1: #e5493a;
	--c2: rgba(28, 28, 28, 0.9);
}

.recent-work-slide.selected .video-thumbnail {
	border: 3px solid #e5493a;
	/* border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
	animation: borderRotate 5s linear infinite forwards; */
    animation: animateColor 3s ease-in-out infinite;
}

@keyframes animateColor {
    0% {
        border: 3px solid #e5493a;
    } 
    50% {
        border: 3px solid #202020;
    }
    100% {
        border: 3px solid #e5493a
    }
}

@keyframes borderRotate {
	100% {
		--angle: 420deg;
	}
}

@keyframes borderRadial {
	20% {
		--gradX: 100%;
		--gradY: 50%;
	}
	40% {
		--gradX: 100%;
		--gradY: 100%;
	}
	60% {
		--gradX: 50%;
		--gradY: 100%;
	}
	80% {
		--gradX: 0%;
		--gradY: 50%;
	}
	100% {
		--gradX: 50%;
		--gradY: 0%;
	}
}


.recent-work-slide.selected {
    transform: scale(1.1);
    z-index: 555; 
}

.swiper-slide {
    display: flex;
    justify-content: center;
    width: calc(100% / 3); 
    z-index: -111;
}

.video-thumbnail video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.recent-work-slider .swiper-pagination {
    margin-bottom: -9px !important;
}

/* Video Player */

.gallery-video-container {
    position: relative;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
}

.gallery-video-container video {
    width: 100%;
    height: auto;
    max-height: 510px;
    display: block;
}

.play-pause-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    color: white;
    opacity: 0;
    transition: opacity 0.3s;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8);
}

.gallery-video-container:hover .play-pause-icon {
    opacity: 1;
}



/* new css */

/* Responsive video thumbnail height for mobile */
@media (max-width: 768px) {
    .video-thumbnail {
        height: 175px; /* Adjust the height as needed */
    }

    .recent-work-slide.selected {
        transform: scale(1.09);
        z-index: 555; 
    }

    .recent-work-slider {
        height: 300px !important;
        padding: 2rem 0;
    }

    .gallery-video-container:hover .play-pause-icon {
        opacity: 0;
        display: none;
    }

    .gallery-video-container video {
        width: 100%;
        height: auto;
        max-height: 160px;
        display: block;
    }
}

