.service-details-row p {
    line-height: 25px;
}

.service-details-line {
    width: 60px;
    height: 1px;
    background-color: #e5493a;
    position: relative; /* Required for the left/right properties to work */
    /* animation: moveLeftRight 5s infinite; */
}



.service-details-title {
    color: #a1a1a1;
    font-family: Segeo-UI-Regular;
    font-size: 1.3em;
    /* margin-bottom: 0.5rem; */
    margin-top: 0.5em;
}

.service-details-title-ar {
    color: #a1a1a1;
    font-family: Hacen-Regular;
    font-size: 1.3em;
    /* margin-bottom: 0.5rem; */
    margin-top: 0.5em;
}

.service-details-description-title {
    font-family: Segeo-UI-Regular;
    /* font-weight: bold; */
    font-size: 1.1em;
    color: #464646;
    transition: color 0.5s ease-in;
}

.service-details-description-title-ar {
    font-family: Hacen-Bold;
    /* font-weight: bold; */
    font-size: 1.1em;
    color: #464646;
    transition: color 0.5s ease-in;
}

.service-details-description {
    max-width: 95%;
    font-family: Segeo-UI-Regular;
    font-weight: lighter;
    font-size: 1.1em;
    color: #464646;
    text-align: justify;
    transition: color 0.5s ease-in;
}

.service-details-description-ar {
    max-width: 95%;
    font-family: Hacen-Regular;
    font-weight: lighter;
    font-size: 1.1em;
    color: #464646;
    text-align: justify;
    transition: color 0.5s ease-in;
}

.custom-col-1, .custom-col-2 {
    flex: 0 0 37.5%; /* 4.5 out of 12 is 37.5% */
    max-width: 37.5%;
    height: 275px;
}

.custom-col-3 {
    flex: 0 0 25%; /* 3 out of 12 is 25% */
    max-width: 25%;
    border: 3px solid #e5493a;
}

.custom-service-col {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    will-change: opacity;
}

.service-details-bottom-title {
    font-size: 0.9em;
    font-family: Segeo-UI-Light;
    /* letter-spacing: 1px; */
    color: #e5493a;
    padding: 0;
    margin-top: 15px;
    margin-bottom: 50px;
}

.third-image-text {
    font-family: Segeo-UI-Bold;
    font-size: 1.1em;
    /* margin-top: 80px; */
    padding-top: 80px;
    margin-left: 25px;
    color: #a1a1a1;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.30);
}

.third-image-text-ar {
    font-family: Hacen-Bold;
    font-size: 1.1em;
    /* margin-top: 80px; */
    padding-top: 80px;
    margin-right: 25px;
    color: #a1a1a1;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.30);
}

.third-image-text-line {
    height: 2.5px;
    width: 75px;
    margin-left: 25px;
    background-color:#e5493a;
    position: relative;
    /* animation: moveLeftRight1 5s infinite; */
}

.third-image-text-ar-line {
    height: 2.5px;
    width: 75px;
    margin-right: 25px;
    background-color:#e5493a;
    position: relative;
    /* animation: moveLeftRight1 5s infinite; */
}

.first-image-col {
    height: 325px;
}

/* .service-details-description-title:hover {
    color: #a1a1a1;
} */

/* .service-details-description:hover {
    color: #a1a1a1;
} */

@media (max-width: 768px) {
    .service-details-title {
        margin-bottom: 1em !important;
    }

    .service-details-title-ar {
        margin-bottom: 1em !important;
    }

    .service-details-bottom-title {
        margin-bottom: 45px;
    }

    .service-details-bottom-title {
        font-size: 0.9em !important;
    }

    .custom-col-1, .custom-col-2 {
        flex: 0 0 50%; /* 4.5 out of 12 is 37.5% */
        max-width: 50%;
    }

    .first-image-col, .custom-col-1, .custom-col-2 {
        height: 150px;
    }
    
    .custom-col-3 {
        flex: 0 0 50%; /* 3 out of 12 is 25% */
        max-width: 50%;
        border: 3px solid #e5493a;
    }

    .service-details-description-title {
        font-size: 1em !important;
    }

    .service-details-description-title-ar {
        font-size: 1em !important;
    }

    .service-details-description {
        max-width: 100%;
        font-size: 0.9em !important;
    }

    .service-details-description-ar {
        max-width: 100%;
        font-size: 0.9em !important;
    }

    /* .service-details-main-row {
        margin-top: 1em !important;
    } */

    .third-image-text {
        font-size: 0.9em;
        padding-top: 25px;
        margin-left: 15px;
    }
    .third-image-text-line {
        margin-left: 15px;
    }

    .third-image-text-ar {
        font-size: 0.9em;
        padding-top: 25px;
        margin-right: 15px;
    }
    .third-image-text-ar-line {
        margin-right: 15px;
    }
}


.service-svg-icon {
    width: 80%;
    height: 80%;
}

.service-svg-icon path {
    animation: fadeInOut 4s infinite ease-in-out;
}

.service-svg-icon:hover path {
    fill: #e5493a;
}

.service-svg-icon1:hover path {
    fill: #464646;
}

@keyframes fadeInOut {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 0.4;
    }
}


@keyframes moveLeftRight {
    0% {
        left: 0;
    }
    50% {
        left: calc(100% - 90px); /* Move to the right, subtracting the width of the div */
    }
    100% {
        left: 0;
    }
}


@keyframes moveLeftRight1 {
    0% {
        left: 0;
    }
    50% {
        left: calc(100% - 115px); /* Move to the right, subtracting the width of the div */
    }
    100% {
        left: 0;
    }
}