/* Here we should specify::
    - HTML tags
    - FONT sizes, families
    - Colors
    - scss variables
*/

@font-face {
    font-family: 'Myriad-Pro-Regular';
    src: url('./assets/fonts/myriad-pro/MYRIADPRO-REGULAR.woff');
}

@font-face {
    font-family: 'Segeo-UI-Bold';
    src: url('./assets/fonts/segeo-ui/Segoe\ UI\ Bold.woff');
}

@font-face {
    font-family: 'Segeo-UI-Regular';
    src: url('./assets/fonts/segeo-ui/Segoe\ UI.woff');
}

@font-face {
    font-family: 'Segeo-UI-Light';
    src: url('./assets//fonts/segeo-ui/Segoe\ UI\ Light.ttf');
}

@font-face {
    font-family: 'Myriad-Pro-Regular';
    src: url('./assets/fonts/myriad-pro/MYRIADPRO-REGULAR.woff');
}

@font-face {
    font-family: 'Hacen-Regular';
    src: url('./assets/fonts/hacen/hacen-bd.TTF');
}

@font-face {
    font-family: 'Hacen-Bold';
    src: url('./assets/fonts/hacen/hacen.TTF');
}

@font-face {
    font-family: 'Hacen-Light';
    src: url('./assets//fonts/hacen/hacen-lt.TTF');
}

html, body {
    font-family: Myriad-Pro-Regular;
    background-color: #202020;
}

.section-title {
    color: #a1a1a1;
    font-size: 2em;
    font-family: Segeo-UI-Bold;
}

.section-title-ar {
    color: #a1a1a1;
    font-size: 2em;
    font-family: Hacen-Bold;
}

.section-description {
    color: #464646;
    font-size: 1.2em;
    font-family: Segeo-UI-Regular;
    transition: color 0.5s ease-in;
}

.section-description-ar {
    color: #464646;
    font-size: 1.2em;
    font-family: Hacen-Regular;
    transition: color 0.5s ease-in;
}

/* .section-description:hover {
    color: #a1a1a1;
} */

@keyframes text-side {
    0% {
        bottom: 120px;
    }
    50% {
        bottom: 590px;
    }
    100% {
        bottom: 120px;
    }
}

.side-text {
    position: absolute;
    color: #a1a1a1 !important;
    left: -20px;
    bottom: 100px;
    transform: rotate(90deg);
    animation: text-side 10s infinite;
    font-size: 1em;
    font-family: Segeo-UI-Light;
    letter-spacing: 6px;
}

.side-text-ar {
    position: absolute;
    color: #a1a1a1 !important;
    left: -20px;
    bottom: 100px;
    transform: rotate(90deg);
    animation: text-side 10s infinite;
    font-size: 1em;
    font-family: Segeo-UI-Light;
    letter-spacing: 6px;
}


@media (max-width: 768px) {
    .section-title {
        color: #a1a1a1;
        font-size: 1.3em;
        font-family: Segeo-UI-Bold;
    }

    .section-title-ar {
        color: #a1a1a1;
        font-size: 1.3em;
        font-family: Hancen-Bold;
    }
    
    .section-description {
        color: #464646;
        font-size: 1.1em;
        font-family: Segeo-UI-Regular;
    }

    .section-description-ar {
        color: #464646;
        font-size: 1.1em;
        font-family: Hacen-Regular;
    }

    .side-text {
        font-size: 0.8em;
        left: -45%;
        width: 100%;
        /* height: 500px; */
        animation: text-side-m 10s infinite;
    }

    .side-text-ar {
        font-size: 0.8em;
        left: -45%;
        width: 100%;
        /* height: 500px; */
        animation: text-side-m-ar 10s infinite;
    }

    .section-title {
        font-size: 1.6em !important;
    }

    .section-title-ar {
        font-size: 1.6em !important;
    }

    @keyframes text-side-m {
        0% {
            bottom: 25px;
        }
        50% {
            bottom: 525px;
        }
        100% {
            bottom: 25px;
        }
    }

    @keyframes text-side-m-ar {
        0% {
            bottom: 250px;
        }
        50% {
            bottom: 700px;
        }
        100% {
            bottom: 250px;
        }
    }
    
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 21px;
    height: 21px;
    margin-top: 1px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 21px;
    height: 21px;
    /* margin: 2px; */
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}