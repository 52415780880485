  
  .hero-swiper {
    width: 100%;
    height: 100%;
    border-radius: 0 !important;
  }
  
  .hero-swiper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    border-radius: 0 !important;
  }
  
  .hero-swiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 !important;
  }

  .heor-swiper .swiper-wrapper .swiper-pagination-bullet {
    background-color: rgb(180, 170, 130) !important;
    width: 16px;
    height: 16px;
 }

 .swiper-pagination {
  margin-bottom: 60px;
 }

 .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 1px solid white;
  margin-right: 8px !important;
  margin-left: 8px !important;
}

 .swiper-pagination-bullet-active {
  background-color: #e5493a;
 }
  





/* Work Swiper CSS */
.workSwiper {
  height: 100vh;
}

.workSwiper .swiper-slide {
  width: 100%;
}

.workSwiper .swiper-pagination-bullet {
  margin-right: 4.2em !important;
  margin-top: 10px !important;
}

.arrow-down-icon {
  font-size: 52px;
  color: rgba(255, 255, 255, 0.9);
  transition: opacity 0.3s;
  opacity: 0.5;
}

.arrow-down-icon:hover {
  opacity: 1;
}

.teams-arrow-down {
  margin-top: 6em;
}

.arrow-container {
  width: 52px; /* Adjust size as needed */
  height: 52px; /* Adjust size as needed */
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.9); /* Optional: Add a border to the circle */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden; /* Ensures the arrow stays within the circle */
  opacity: 0.5;
  transition: opacity 0.3s;
}

.arrow-container:hover {
  opacity: 1;
}

.arrow-icon {
  font-size: 36px; /* Adjust size as needed */
  color: rgba(255, 255, 255, 0.9);
  position: absolute;
  animation: moveUpDown 3s infinite ease-in-out;
}

@keyframes moveUpDown {
  0% {
    top: -90%; /* Start near the top */
  }
  100% {
    top: 105%; /* Jump back to the top */
  }
}


@media (max-width: 786px) {

  .products-hero-title {
    font-size: 30px !important;
  }

  .swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    margin-right: 4px !important;
    margin-left: 4px !important;
  }

  .workSwiper .swiper-pagination-bullet {
    margin-right: 1em !important;
  }


  .arrow-down-icon {
    font-size: 45px;
    color: rgba(255, 255, 255, 0.9);
    transition: opacity 0.3s;
    opacity: 0.5;
  }

  .arrow-container {
     width: 45px;
     height: 45px;
  }
  
}