html {
    scroll-behavior: smooth;
}

.navbar-custom-background {
    margin-top: 0 !important;
    background-color: #1b1b1b !important;
    opacity: 0.95;
    transition: 1s background-color ease-in-out;
    max-height: 95px;
}

.mobile-links {
    display: none;
}

.navbar-toggler {
    border: none;
  }

.navbar-toggler-icon {
    background-image: url('../images/toggle-icon.png');
    background-size: cover;
    width: 40px;
    height: 40px;
  }


/* .navbar-brand img {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.30);
} */


@media (max-width: 786px) {
    .navbar-brand img {
        width: 110px;
    }
    .navbar-brand {
        margin-left: 10px !important;
    }
    .mobile-links {
        display: block;
    }
    .not-mobile-links {
        display: none;
    }

    .navbar-collapse {
        /* background-color: rgba(30, 30, 30, 0.7); */
        /* background: black !important; */
        background: linear-gradient(to bottom, transparent 5%, rgba(15, 15, 15, 0.3) 85%);
    }

    .navbar-collapse.show {
        /* transition: all 3s; */
        /* background: red;
        transition: all 0s; */
        /* background-color: rgba(30, 30, 30, 0.7); */
        /* margin-top: 10px; */
        /* transition: all 0.5s; */
        /* transform: translateY(-10px); */
      }

      .wide-screen-links {
        display: none !important;
      }

    /* .header-login-button {
        margin-top: 80px !important;
        margin-bottom: 80px !important;
    } */
    .teams-hero-slogen-div {
        width: 100%;
    }
    .teams-hero-slogen {
        font-size: 1.3em !important;
    }

    .teams-hero-slogen-desc {
        font-size: 1.5em !important;
    }

    .my-header-link {
        font-size: 1em !important;
        text-align: right;
        
    }

    /* .navbar-collapse {
        margin-left: 60%;
        width: 10px !important;
    }


    .navbar-collapse.show {
        margin-left: 60%;
        width: 10px !important;
    } */

}

.my-header-link {
    font-family: Myriad-Pro-Regular;
    letter-spacing: 2px;
    font-size: 1.2em;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.30);
}

.my-header-link-ar {
    font-family: Hacen-Regular;
    letter-spacing: 0;
    font-size: 1.2em;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.30);
}

.header-login-button {
    border: 1px solid white;
    border-radius: 35px;
    color: rgb(180, 169, 130);
}

.header-login-button:hover {
    border: 1px solid white;
    border-radius: 35px;
    background-color: rgb(180, 169, 130);
    color: white;
}


.navbar-toggler, .navbar-toggler:hover, .navbar-toggler:focus {
    border: none !important;
    outline: none !important;
    box-sizing: unset !important;
    box-shadow: none !important;
}

.language-dropdown-item:hover {
    background-color: rgb(15, 15, 15);
}

.rotate-90 {
    animation: rotate90 0.5s forwards;
}

.rotate-back {
    animation: rotateBack 0.5s forwards;
}

@keyframes rotate90 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(90deg);
    }
}

@keyframes rotateBack {
    from {
        transform: rotate(90deg);
    }
    to {
        transform: rotate(0deg);
    }
}


.my-header-link.header-active-link {
    position: relative;
}

.my-header-link.header-active-link::after {
    content: '';
    position: absolute;
    bottom: 0; /* Position the border at the bottom of the link */
    left: 0;
    width: 100%;
    height: 2px; /* Height of the border */
    background: white; /* Border color */
    transform: scaleX(0); /* Start with scale 0 to create drawing effect */
    transform-origin: left; /* Start drawing from the left */
    transition: transform 0.5s ease; /* Smooth transition for drawing effect */
}

.my-header-link.header-active-link::after {
    animation: drawBorder 0.5s forwards;
}

@keyframes drawBorder {
    0% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
}

/* .my-header-link:hover {
    color: red !important;
} */


/* Custom styles for the navbar on mobile */
@media (max-width: 767.98px) {
    .navbar-collapse {
        position: fixed;
        top: 8%;
        right: 0;
        width: 32%;
        height: 225px;
        background-color: transparent;
        transition: all 0.3s ease-in-out;
        z-index: 1050; /* Ensure it's on top of other elements */
    }

    .navbar-collapse-ar {
        height: 250px;
    }

    /* When the navbar is collapsed (hidden), slide it off-screen */
    /* .navbar-collapse.collapsing,
    .navbar-collapse:not(.show) {
        right: -100%;
    } */

    /* When the navbar is expanded (visible), position it on the right */
    /* .navbar-collapse.show {
        right: 0;
    } */
}
