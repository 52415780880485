/* AboutUs.css */

.about-us-section {
    background-color: #1c1c1c;
    color: #fff;
    min-height: 100%;
    position: relative;
}

.about-us-img {
    width: 100%;
    min-height: 375px;
    padding: 0;
    /* background: url('../images/slide1.png') no-repeat center center; */
    background-size: cover;
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
}

/* .about-us-content {
    padding: 3% 5%;
} */

.title-section .line {
    width: 50px;
    height: 3px;
    background-color: #fff;
    margin: 0 auto;
}

.section-title {
    font-size: 2rem;
    font-weight: bold;
}

.section-title-ar {
    font-size: 2rem;
}

.info-row {
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.info-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
}

.person-info-icon {
    width: 20px !important;
    animation: fadeInOut 3s infinite;
}

.info-icon {
    width: 24px;
    height: auto;
    /* margin-bottom: 0.5rem; */
}

.info-text {
    font-size: 1.2em;
    font-family: 'Segeo-UI-Regular';
    color: #464646;
}

.about-description {
    text-align: justify;
    text-align-last: center;
}


.animated-circle {
    position: absolute;
    top: 75px;
    /* right: 225px; */
    left: 200px;
    width: 25px;
    height: 25px;
    background-color: #e5493a;
    border-radius: 50%;
    animation: fadeInOut 3s infinite;
    font-size: larger;
    font-size: 100px;
}

.animated-circle-text {
    position: absolute;
    top: 50px;
    animation: fadeInOut 3s infinite;
    /* right: 228px; */
    left: 202px;
}

.our-vision-span {
    font-weight: bolder;
}

.our-vision-span-ar {
    font-family: 'Hacen-Bold';
}

@keyframes fadeInOut {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

.about-description-div {
    max-width: 80%;
}


@media (max-width: 768px) {
    .animated-circle {
        position: absolute;
        top: 30px;
        left: 50px;
        width: 15px;
        height: 15px;
    }
    
    .animated-circle-text {
        position: absolute;
        top: 9px;
        animation: fadeInOut 3s infinite;
        left: 47px;
    }

    .about-us-image {
        min-height: 130px;
    }

    .info-icon {
        width: 18px;
        margin-right: 5px !important;
    }
    
    .info-text {
        text-align: center;
        font-size: 0.8em;;
    }

    .person-info-icon {
        width: 18px !important;
    }

    .about-us-img {
        height: 175px;
    }

    .about-description-div {
        max-width: 85%;
    }

    .info-item {
        padding-left: 10px;
        padding-right: 0px;
    }

    .about-description-mobile {
        margin-bottom: 2em;
    }
}
