/* TwoSectionsWithLine.css */


.split-line {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3px;
    width: 100%;
    position: relative;
}

.line-half {
    height: 3px;
}

.red {
    align-self: flex-start;
    background-color: #e5493a;
    width: 0%;
    transition: width 2.5s ease-in-out;
}

.white {
    align-self: flex-end;
    background-color: #404040;
    width: 50%;
}

.animate-line {
    width: 50% !important;
}
