.clients-slider-section {
    display: grid;
    place-items: center;
    background-color: #202020;
}

.clients-slider {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: transparent;
    border-radius: 30px;
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(7.4px);
    -webkit-backdrop-filter: blur(7.4px);
    /* border: 1px solid white; */
    /* background-color: rgba(0, 0, 0, 0.2); */
}

.clients-slider-items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    animation: scrolling 20s linear infinite;

}

@keyframes scrolling {
    0% {
        transform: translateX(80%);
    }

    50% {
        transform: translateX(-20%);
    }

    100% {
        transform: translateX(80%);
    }
    /* 0% {
        transform: translateX(0%);
    }
    
    100% {
        transform: translateX(-50%);
    } */
}
